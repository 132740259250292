<template>
  <div class="container bg">
    <div class="navBox dfc al-c">
      <el-page-header @back="goBack" content="真人克隆"> </el-page-header>
      <div class="dfc al-c">
        <div>
          数字人克隆数量 ：<span class="fb fs16" style="color: #015de7">{{ info.calculations == 2 ? info.high_image_copy : info.image_copy }}</span> 次
        </div>
        <div v-if="info.calculations == 3" class="ml10">
          高阶克隆数量 : <span class="fb fs16" style="color: #015de7">{{ info.high_image_copy }}</span> 次
        </div>
        <div class="listIcon ml20 mr10" style="cursor: pointer" @click="getList">
          <i class="el-icon-notebook-2"></i>
        </div>
      </div>
    </div>
    <div class="pt20">
      <el-row style="">
        <el-col :span="16" :offset="4">
          <div class="box">
            <div class="top">
              <div>1：1真人克隆数字人</div>
              <div class="mt10 fs18">提交2分钟录制画面，1：1还原真人神态，替代真人出镜，适用多种营销场景。</div>
            </div>

            <!-- <div class="mt20 dfc" style="justify-content: flex-end">
              <div style="color: #f56c6c" class="mt30 fs18">
                当前剩余形象克隆 {{ info.calculations == 2 ? info.high_image_copy : info.image_copy }} 次
                <span v-if="info.calculations == 3">,高阶克隆 {{ info.high_image_copy }} 次 </span>
              </div>
              <div class="listIcon ml450 mr10" style="cursor: pointer" @click="getList">
                <i class="el-icon-notebook-2"></i>
              </div>
            </div> -->
            <div class="info">
              <div class="df" v-if="info.calculations == 3">
                <div class="label">克隆模式：</div>
                <div>
                  <el-radio v-model="radio" :label="2">高阶克隆</el-radio>
                  <el-radio v-model="radio" :label="1">形象克隆</el-radio>
                </div>
              </div>

              <div class="dfc mt20">
                <div class="label">形象名称：</div>
                <el-input v-model="userName" placeholder="请输入"></el-input>
              </div>

              <div class="mt20">
                <div class="label">克隆素材：</div>
                <div class="mt20">
                  <upload-video ref="uploads" :higher="info.calculations == 3 ? radio : info.calculations * 1"></upload-video>
                </div>
              </div>
            </div>
            <div class="tips left">保证数字人形象定制克隆的效果，请阅读&nbsp;《视频拍摄规范》后录制视频。</div>
            <div class="left mt15">
              <el-button type="primary" @click="debounceClick">提交审核</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="审核列表" :visible.sync="dialogVisible" width="42%">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="形象名称" align="center"></el-table-column>
        <el-table-column prop="province" align="center" label="克隆素材" width="230">
          <template slot-scope="scope">
            <div class="dfc">
              <el-link v-if="scope.row.url1" type="primary" @click="showVideo(scope.row.url1)">素材文件1</el-link>
              <el-link v-if="scope.row.url2" type="primary" @click="showVideo(scope.row.url2)">素材文件2</el-link>
              <el-link v-if="scope.row.url3" type="primary" @click="showVideo(scope.row.url3)">素材文件3</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间" width="130"></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.audit == 0">待审核</el-tag>
            <el-tag v-show="scope.row.audit == 1" type="success">审核完成</el-tag>
            <el-tag v-show="scope.row.audit == 2" type="danger">审核失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" width="150">
          <template slot-scope="scope">
            <el-popconfirm v-if="scope.row.audit == 2" title="是否重新编辑素材信息？" @confirm="editItem(scope.row)">
              <span slot="reference" class="underline-text" style="color: #015de7">{{ scope.row.failure_text }}</span>
            </el-popconfirm>
            <span v-else>{{ scope.row.failure_text }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog width="30%" title="克隆素材" :visible.sync="innerVisible" :destroy-on-close="true" append-to-body>
        <div class="dfc" v-if="innerVisible">
          <video :src="vdSrc" class="vdBox" autoplay controls></video>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="innerVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import UploadVideo from "@/components/UploadVideo.vue";
import { getUser } from "@/api/user.js";
import { sut_image_copy, set_image_copy, image_copy_list } from "@/api/video";
export default {
  components: {
    UploadVideo,
  },
  data() {
    return {
      vdSrc: "",
      userName: "",
      radio: 2,
      editId: null,
      timer: null,
      info: {},
      tableData: [],
      dialogVisible: false,
      innerVisible: false,
    };
  },
  watch: {
    radio() {
      this.$refs.uploads.clearFile();
    },
  },
  mounted() {
    // 渲染视频

    console.log(this.info);
    this.getInfo();
  },
  methods: {
    getInfo() {
      getUser().then((res1) => {
        if (res1.code == "200") {
          localStorage.setItem("userInfo", JSON.stringify(res1.data));
          this.info = res1.data;
        }
      });
    },
    getList() {
      image_copy_list().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    showVideo(url) {
      this.vdSrc = url;
      this.innerVisible = true;
    },
    goBack() {
      this.$router.push({ name: "home" });
    },
    editItem(e) {
      console.log("editItem", e);
      this.userName = e.name;
      this.editId = e.id;
      this.dialogVisible = false;
    },
    debounceClick() {
      // 防抖代码
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.submit();
      }, 1200);
    },
    submit() {
      console.log(111);
      console.log(this.$refs.uploads.fileList);
      if (!this.userName) {
        this.$message.error("请输入形象名称");
        return;
      }
      if (this.$refs.uploads.fileList.length < 1) {
        this.$message.error("请上传视频后提交克隆");
        return;
      }
      if ((this.info.calculations == 1 || this.radio == 1) && this.$refs.uploads.fileList.length < 3) {
        this.$message.error("请上传三段视频后提交克隆");
        return;
      }

      let params = {
        name: this.userName,
        copy_type: this.info.calculations == 3 ? this.radio : this.info.calculations,
        url1: this.$refs.uploads.fileList[0],
      };
      if (this.info.calculations == 1 || this.radio == 1) {
        params.url2 = this.$refs.uploads.fileList[1];
        params.url3 = this.$refs.uploads.fileList[2];
      }

      let result = null;
      if (this.editId != null) {
        params.image_id = this.editId;
        result = set_image_copy(params);
      } else {
        result = sut_image_copy(params);
      }
      result.then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.$message.success(res.msg);
          this.userName = "";
          this.editId = null
          this.$refs.uploads.clearFile();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  // background: #fff;
  margin: 20px 0;
  margin-bottom: 0;
  height: 88vh;
  border-radius: 15px;
  overflow: hidden;

  .top {
    width: 100%;
    height: 140px;
    background: url(../../assets/img/clongBg.png) no-repeat;
    background-size: 100% 100%;
    padding: 40px 30px;
    border-radius: 15px;
    font-size: 28px;
    margin-top: 20px;
  }

  .info {
    text-align: left;
    margin: 40px 20px 25px;
    font-size: 16px;
    background: #f5f9ff;
    border-radius: 10px;
    padding: 10px 20px;
    width: 40vw;

    .label {
      width: 8vw;
    }

    .timeBox {
      height: 50px;
      line-height: 50px;

      .icon1 {
        font-size: 18px;
        color: #006dfe;
        margin-right: 12px;
      }
    }
  }
  .tips {
    color: #808080;
    font-size: 14px;
  }

  .left {
    text-align: left;
    margin-left: 30px;
  }
}

.listIcon {
  font-size: 28px;
  padding: 3px 8px 2px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  // width: 50px;
  color: #53a8ff;
  text-align: center;
}

// /deep/.el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
/deep/.el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.vdBox {
  width: 315px;
  margin: 0 auto;
}

.navBox {
  background: #fff;
  padding: 10px 20px 10px;
}
</style>
